import React from "react";
import "../../Styles/Contact.css";
import email_icon from "../../Assets/email_icon.png";
// import contact_icon from "../../Assets/contact_icon.png";
import location from "../../Assets/location_icon.png";
// import axios from "axios";
// import qs from "qs";
// import { PulseLoader } from "react-spinners";
// import thanks_tick from "../../Assets/thanks_tick.png";
// import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
function Contact() {
  // const [data, setData] = useState({
  //   NAME: "",
  //   EMAIL: "",
  //   MOB: "",
  //   MESSAGE: "",
  // });
  // const [loading, setLoading] = useState(false);
  // const [status, setStatus] = useState();
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   const formattedValue = name === "MOB" ? value.replace(/\D/g, "") : value;

  //   setData((prevProps) => ({
  //     ...prevProps,
  //     [name]: formattedValue.trimStart(),
  //   }));
  // };
  // const handlePhoneChange = (value) => {
  //   setData((prevProps) => ({
  //     ...prevProps,
  //     MOB: value, // Update mobile number with country code
  //   }));
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(data, "data");
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: "http://192.168.1.107:5000/contact-form",
  //     data: qs.stringify(data),
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //   }).then((res) => {
  //     console.log(res, "response");
  //     setStatus(res.data.XscStatus);
  //     setLoading(false);
  //   });
  // };
  return (
    <div className="container" id="contact">
      <div id="contact_bg">
        <div className="row">
          <div className="col-lg-7 col-md-12" id="contact_text_bg">
            <div id="contact_us">Contact Us</div>
            <div id="reach_text">
              Reach out for any enquiries, support, or orders.
              <br className="d-lg-block d-none" />
              let’s start a conversation
            </div>
            <div id="reach_bg">
              <div id="reach_sing_bg">
                <div id="email_bg">
                  <img src={email_icon} alt="" />
                </div>
                <div id="email_text">
                  Email<div id="email_id">connect@unprll.com</div>
                </div>
              </div>
              {/* <div id="reach_sing_bg">
                <div id="email_bg">
                  <img src={contact_icon} alt="" />
                </div>
                <div id="email_text">
                  Phone Number<div id="email_id">+1 551 998 5696</div>
                </div>
              </div> */}
              <div id="reach_sing_bg">
                <div id="email_bg">
                  <img src={location} alt="" />
                </div>
                <div id="email_text">
                  Registered Office
                  <div id="email_id">
                    254 Chapman Road, Ste 209 Newark, <br /> DE 19702, USA
                  </div>
                </div>
              </div>
              <div id="reach_sing_bg">
                <div id="email_bg">
                  <img src={location} alt="" />
                </div>
                <div id="email_text">
                  Corporate Office
                  <div id="email_id">
                    447 Broadway, 2nd Floor #1338 New York, <br />
                    NY 10013, USA
                  </div>
                </div>
              </div>
              <div id="reach_sing_bg">
                <div id="email_bg">
                  <img src={location} alt="" />
                </div>
                <div id="email_text">
                  Manufacturing & Global Delivery
                  <div id="email_id">
                    No.1, 100 Feet Outer Ring Rd, Pine Hurst Building, <br />BTM Layout 2nd Stage, Bengaluru, KA 560076, India
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12" id="contact_form_bg">
            {/* <div > */}
            {/* <form onSubmit={handleSubmit}>
                <div id="contact_form">
                  <div>
                    <label className="mt-0">Name:</label>
                    <div>
                      <input
                        placeholder="Enter Your Full Name"
                        name="NAME"
                        type="text"
                        required
                        value={data.NAME}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Email:</label>
                    <div>
                      <input
                        placeholder="Enter Your Email"
                        name="EMAIL"
                        type="email"
                        value={data.EMAIL}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label>Phone Number:</label>
                    <div>
                      <PhoneInput
                        className="phoneInput"
                        defaultCountry="in"
                        value={data.MOB}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Message:</label>
                    <div>
                      <textarea
                        name="MESSAGE"
                        onChange={handleInputChange}
                        required
                        value={data.MESSAGE}
                      />
                    </div>
                  </div>
                </div>
                {status !== 1 ? (
                  <button className="btn" id="submit_btn">
                    SUBMIT {loading && <PulseLoader color="white" size={6} />}
                  </button>
                ) : (
                  <button className="btn" id="thanks_submit_btn">
                    <img src={thanks_tick} alt="" />
                    Thanks for submitting{" "}
                    {loading && <PulseLoader color="white" size={6} />}
                  </button>
                )}
              </form> */}

            <iframe
              className="p-0 m-0"
              title="contactform"
              src="https://c09669fb.sibforms.com/serve/MUIFAF7XzehyxwFk2pDsKbmSZI2z9xmuF6uJdene-9iTrJRhB3iQBDvHonfBzgrPk0oGRiERxvc2frj6UjUTZMshCFu57YDfliAaTudRwhBRgXUTfubgOAQ29apaMbZoPhH6m_4GWVwRdWEgA8-4Xni6ECvk3UnS5Eu28urPxKkULAiWVRE3MnZUC2kbi5I6xGJkdvT8r4a0dcym"
              frameborder="0"
              scrolling="no"
              style={{ padding: "0px" }}
              // id="myIframe"
            ></iframe>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
