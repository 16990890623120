import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Components/Homepage/Homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "./Components/Policies/Cookies";
import Privacy from "./Components/Policies/Privacy";
import TermsConditions from "./Components/Policies/Terms";
import "./App.scss"
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/cookies-policy" element={<Cookies />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
