import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import top_bar_img1 from "../../Assets/topbar_img1.jpg";
import top_bar_img2 from "../../Assets/top_bar_img2.jpg";
import top_bar_img3 from "../../Assets/top_bar_img3.jpg";
import top_bar_img4 from "../../Assets/top_bar_img4.jpg";
import top_bar_img1_tab from "../../Assets/top_bar_img1_tab.jpg";
import top_bar_img2_tab from "../../Assets/top_bar_img2_tab.jpg";
import top_bar_img3_tab from "../../Assets/top_bar_img3_tab.jpg";
import top_bar_img4_tab from "../../Assets/top_bar_img4_tab.jpg";
import mob_top_bar_img1 from "../../Assets/mob_topbar_img1.jpg";
import mob_top_bar_img2 from "../../Assets/mob_topbar_img2.jpg";
import mob_top_bar_img3 from "../../Assets/mob_topbar_img3.jpg";
import mob_top_bar_img4 from "../../Assets/mob_top_bar_img4.jpg";
import "../../Styles/HeroSection.css";

function HeroSection() {
  const [activeSlide, setActiveSlide] = useState(0);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    waitForAnimate: false,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next), // Update activeSlide
    appendDots: (dots) => (
      <div
        style={{
          paddingBottom: "40px",
          paddingLeft: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            gap: "10px",
          }}
        >
          {dots}{" "}
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "40px",
          height: "4px",
          backgroundColor: i === activeSlide ? "#D11826" : "white",
          transition: "background-color 0.3s ease",
        }}
      />
    ),
  };
  
  return (
    <div>
      <div className="container slider d-lg-block d-md-none d-none">
        <Slider {...settings}>
          <div className="w-100">
            <img src={top_bar_img1} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img2} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img3} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img4} alt="" width="100%" />
          </div>
        </Slider>
      </div>
      <div className="container slider d-lg-none d-md-block d-none">
        <Slider {...settings} >
          <div className="w-100">
            <img src={top_bar_img1_tab} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img2_tab} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img3_tab} alt="" width="100%" />
          </div>
          <div>
            <img src={top_bar_img4_tab} alt="" width="100%" />
          </div>
        </Slider>
      </div>
      <div className="container slider d-lg-none d-md-none d-block">
        <Slider {...settings} >
          <div className="w-100">
            <img src={mob_top_bar_img1} alt="" width="100%" />
          </div>
          <div>
            <img src={mob_top_bar_img2} alt="" width="100%" />
          </div>
          <div>
            <img src={mob_top_bar_img3} alt="" width="100%" />
          </div>
          <div>
            <img src={mob_top_bar_img4} alt="" width="100%" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default HeroSection;
