import React from "react";
import "../../Styles/Suppliers.css";
import world_img from "../../Assets/world_img.png";
import smooth_img from "../../Assets/smooth_img.png";
import t_shirt from "../../Assets/tshirt_img.png";
import happy_cust from "../../Assets/happy_cus_img.png";
import mobile_happy_cust from "../../Assets/happy_cust_mob1.png";
function Suppliers() {
  return (
    <div className="container" id="suppliers_bg">
  <div className="row p-0 m-0">
   
    <div className="col-xl-4 col-lg-4 col-md-6" id="suppliers_img_bg">
      <div id="world_bg">
        Supplying all
        <br /> around <br />
        the world
        <div id="world_inner_text">
          With reliable shipping and logistics
        </div>
        <img src={world_img} alt="" id="world_img" width="100%" />
      </div>
    </div>
    
  
    <div className="col-xl-8 col-lg-8 col-md-6" id="suppliers_img_bg">
      <div id="smooth_bg">
        <img src={smooth_img} alt="" />
        <div id="smooth_text">
          Ensuring Smooth Process
          <div id="smooth_subtext">
            Committed to providing a flawless journey from start to finish
          </div>
        </div>
      </div>
      
   
      <div className="row d-xl-flex d-lg-flex d-md-none d-block">
        <div className="col-lg-6">
          <div id="customize_bg" >
            <img src={t_shirt} alt="" />
            <div id="customize_text">
              Customize
              <div id="smooth_subtext">
                Solutions to meet your unique apparel needs
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div id="happy_bg">
            <img src={happy_cust} alt="" width="100%" height="100%" className="d-lg-block d-md-none d-none" />
            <img src={mobile_happy_cust} alt="" width="100%" className="d-lg-none d-md-none d-block" />
          </div>
        </div>
      </div>
    </div>


    <div className="row d-xl-none d-lg-none d-md-flex d-none">
      <div className="col-md-6" id="suppliers_img_bg">
        <div id="customize_bg">
          <img src={t_shirt} alt="" width="100%" />
          <div id="customize_text">
            Customize
            <div id="smooth_subtext">
              Solutions to meet your unique apparel needs
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6" id="suppliers_img_bg">
        <div>
          <img id="happy_cust_img_tab" src={mobile_happy_cust} alt="" width="100%" className="d-lg-none d-xl-none d-md-block d-block" />
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default Suppliers;
