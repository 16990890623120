import React from "react";
import "../../Styles/Collection.css";
import collection_img1 from "../../Assets/collection_img1.png";
import collection_img2 from "../../Assets/collection_img2.png";
import collection_img3 from "../../Assets/collection_img3.png";
import collection_img4 from "../../Assets/collection_img4.png";
import collection_img5 from "../../Assets/collection_img5.png";
import collection_img6 from "../../Assets/collection_img6.png";
import men_img from "../../Assets/men_img.png";
import women_img from "../../Assets/women_img.png";
import kids_img from "../../Assets/kids_img.png";
import collection_men_img from "../../Assets/collection_men_tab.png";
import collection_women_img from "../../Assets/collection_women_tab.png";
import collection_kids_img from "../../Assets/collection_kids_tab.png";
function Collection() {
  return (
    <div className="container" id="collection">
      <div id="collection_text">FEATURED COLLECTION</div>
      <div className=" " id="collection_bg">
        <div>
          <img src={collection_img1} alt=""/>
          <div id="tshirt_text">POLO T-SHIRT</div>
        </div>
        <div>
          <img src={collection_img2} alt=""/>
          <div id="tshirt_text">Hoodie</div>
        </div>
        <div>
          <img src={collection_img3} alt=""/>
          <div id="tshirt_text">v neck</div>
        </div>
        <div>
          <img src={collection_img4} alt=""/>
          <div id="tshirt_text">CREW NECK</div>
        </div>
        <div>
          <img src={collection_img5} alt=""/>
          <div id="tshirt_text">ROUND NECK</div>
        </div>
        <div>
          <img src={collection_img6} alt=""/>
          <div id="tshirt_text">jersey</div>
        </div>
      </div>
      <div className="row d-lg-flex d-block d-md-none" id="types_bg">
        <div className="col-md-4">
          <div id="mens_bg">
            <img src={men_img} alt="" width="100%" />
          </div>
        </div>
        <div className="col-md-4 ">
          <div id="mens_bg">
            <img src={women_img} alt="" width="100%" />
          </div>
        </div>
        <div className="col-md-4 ">
          <div id="kids_bg">
            <img src={kids_img} alt="" width="100%" />
          </div>
        </div>
      </div>
      <div id="tab_types_bg" className="d-lg-none d-none d-md-block">
        <div id="tab_mens_bg">
          <img src={collection_men_img} alt="" />
        </div>
        <div id="tab_mens_bg">
          <img src={collection_women_img} alt="" />
        </div>
        <div id="tab_mens_bg">
          <img src={collection_kids_img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Collection;
