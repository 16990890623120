import React from "react";
import "../../Styles/Footer.css";
import footer_logo from "../../Assets/un_footer_logo.png";
import insta_img from "../../Assets/insta_img.png";
import linked_img from "../../Assets/linked_img.png";
import yt_img from "../../Assets/yt_img.png";
import spacex_img from "../../Assets/spacex_img.png";
function Footer() {
  return (
    <div id="footer_bg">
      <div className="container">
        <div className="d-flex justify-content-between" id="footer_logo_bg">
          <a href="/">
            <div id="footer_logo">
              <img src={footer_logo} alt="" />
            </div>
          </a>

          <div id="footer_imgs">
            <a href="https://www.instagram.com/unprll/" target="_blank" rel="noreferrer">
              <div>
                <img src={insta_img} alt="" />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/unprll" target="_blank" rel="noreferrer">
              <div>
                <img src={linked_img} alt="" />
              </div>
            </a>
            <a href="https://www.youtube.com/@UNPRLL" target="_blank" rel="noreferrer">
              <div>
                <img src={yt_img} alt="" />
              </div>
            </a>

            <a href="https://x.com/unprlloffcial" target="_blank" rel="noreferrer">
              <div>
                <img src={spacex_img} alt="" />
              </div>
            </a>
          </div>
        </div>
        <div id="footer_divider"></div>
        <div className="d-flex" id="terms_bg">
          <a
            href="/terms-and-conditions"
            className="text-decoration-none text-white"
          >
            <div>Terms Of Use</div>
          </a>
          <a href="/privacy-policy" className="text-decoration-none text-white">
            <div>Privacy Policy</div>
          </a>
          <a href="/cookies-policy" className="text-decoration-none text-white">
            <div>Cookies Policy</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
