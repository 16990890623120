import React from "react";
import "../../Styles/PartnerIndustry.css";
import industry_img1 from "../../Assets/industry_img1.png";
import industry_img2 from "../../Assets/industry_img2.png";
import industry_img3 from "../../Assets/industry_img3.png";
import industry_img4 from "../../Assets/industry_img4.png";
import industry_img5 from "../../Assets/industry_img5.png";
function PartnerIndustry() {
  return (
    <div className="container" id="industry">
      <div id="partner">PARTNERED</div>
      <div id="industry">INDUSTRY</div>
      <div id="partner_subtext">
      Unprll collaborates with leading textile manufacturers to source the finest materials for our apparel
      </div>

      <div className="image-section">
        <img src={industry_img1} alt="partner1"></img>
        <img src={industry_img2} alt="partner2"></img>
        <img src={industry_img3} alt="partner3"></img>
        <img src={industry_img4} alt="partner4"></img>
        <img src={industry_img5} alt="partner5"></img>
      </div>
    </div>
  );
}
export default PartnerIndustry;
