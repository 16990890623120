import React from "react";
import Header from "../Homepage/Header";
import "../../Styles/Policies.css";
import privacy_img from "../../Assets/terms_img.png";
import Footer from "../Homepage/Footer";
function Cookies() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row p-0 m-0" id="privacy_bg">
          <div className="col-md-6">
            <div id="terms_text">Cookie Policy</div>
          </div>
          <div className="col-md-6  d-lg-block d-md-none d-none" id="privacy_img">
            <img src={privacy_img} alt="" />
          </div>
        </div>
        <div id="terms_conditions_bg">
          <div>
            <div id="terms_heading">1. What Are Cookies?</div>
            <div id="terms_content">
              Cookies are small text files placed on your device (computer,
              tablet, or smartphone) when you visit a website. Cookies help
              websites recognize your device and store specific information
              about your preferences or actions.
            </div>
          </div>
          <div>
            <div id="terms_heading">2. Types of Cookies We Use</div>
            <div id="terms_content">
              We use different types of cookies on our website, each serving
              specific functions:
              <ul>
                <li>
                  Essential Cookies: These cookies are necessary for the website
                  to function properly. They enable core features like secure
                  logins, shopping cart functionality, and payment processing.
                  Without these cookies, the website may not work as intended.
                </li>
                <li>
                  Performance and Analytics Cookies: These cookies collect
                  information about how visitors use our website, such as which
                  pages are most frequently visited and if users encounter error
                  messages. This data helps us improve website performance and
                  enhance user experience.
                </li>
                <li>
                  Functional Cookies: These cookies allow the website to
                  remember choices you make (like your preferred language or
                  region) to provide enhanced and personalized features.
                </li>
                <li>
                  Marketing and Advertising Cookies: These cookies track your
                  browsing habits and help us deliver ads relevant to your
                  interests. They are also used to limit the number of times you
                  see an ad and measure the effectiveness of marketing
                  campaigns. These cookies are typically placed by third-party
                  advertising networks.
                </li>
                <li>
                  Third-Party Cookies: In addition to our own cookies, we may
                  work with third-party service providers (e.g., Google
                  Analytics, social media platforms) that set cookies to provide
                  additional features or track performance metrics.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">3. How We Use Cookies</div>
            <div id="terms_content">
              We use cookies to:
              <ul>
                <li>Ensure the smooth functioning of our website.</li>
                <li>
                  Analyze user behavior to improve our services and website
                  performance.
                </li>
                <li>
                  Personalize your experience by remembering your preferences.
                </li>
                <li>Show relevant ads tailored to your interests.</li>
                <li>
                  Enable social media integration, such as sharing content on
                  platforms like Facebook or Instagram.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">4. Managing Cookies</div>
            <div id="terms_content">
              You have the right to accept or reject cookies. When visiting our
              website, you will be presented with a cookie consent banner giving
              you the option to manage your preferences. You can choose to
              accept all cookies, reject non-essential cookies, or customize
              which types of cookies you allow. Additionally, you can manage or
              block cookies through your browser settings. Here are the links to
              cookie management guides for popular browsers: Google Chrome:
              Chrome Cookie Settings
              <ul>
                <li>Mozilla Firefox: Firefox Cookie Settings</li>
                <li>Safari: Safari Cookie Settings</li>
                <li>Microsoft Edge: Edge Cookie Settings</li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">5. Cookies We Use</div>
            <div id="terms_content">
              Here is a list of some common cookies we may use:
              <ul>
                <li>
                  Session Cookies: These cookies are temporary and are deleted
                  once you close your browser.
                </li>
                <li>
                  Persistent Cookies: These cookies remain on your device until
                  they expire or you manually delete them.
                </li>
                <li>
                  First-Party Cookies: Cookies set by Unprll that collect data
                  solely for our use.
                </li>
                <li>
                  Third-Party Cookies: Cookies set by third-party services
                  (e.g., Google, Facebook) to track usage or offer additional
                  services.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">6. How Long Are Cookies Stored?</div>
            <div id="terms_content">
              Cookies are stored on your device for different periods of time:
              <li>Session Cookies: Expire when you close your browser.</li>
              <li>
                Persistent Cookies: Remain on your device until a specific
                expiration date or until manually deleted.
              </li>
              <li>
                We review the duration of cookies periodically to ensure they
                serve their purpose and align with legal requirements.
              </li>
            </div>
          </div>
          <div>
            <div id="terms_heading">7. Third-Party Cookies</div>
            <div id="terms_content">
              Some cookies on our site are placed by third-party services, such
              as analytics and advertising partners. These third parties may
              collect information about your online activities across different
              websites. We do not control these cookies, and we recommend
              reviewing the privacy policies of the respective third parties to
              learn more about their data collection practices.
            </div>
          </div>

          <div>
            <div id="terms_heading">8. Updates to This Cookie Policy</div>
            <div id="terms_content">
              We may update this Cookie Policy from time to time to reflect
              changes in technology, legislation, or our business practices. We
              will notify you of any significant changes by posting the revised
              policy on this page with an updated "Effective Date."
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Cookies;
