import React from "react";
import Header from "../Homepage/Header";
import "../../Styles/Policies.css";
import privacy_img from "../../Assets/terms_img.png";
import Footer from "../Homepage/Footer";
function Privacy() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row p-0 m-0" id="privacy_bg">
          <div className="col-md-6">
            <div id="terms_text">Privacy Policy</div>
          </div>
          <div className="col-md-6  d-lg-block d-md-none d-none" id="privacy_img">
            <img src={privacy_img} alt="" />
          </div>
        </div>
        <div id="terms_conditions_bg">
          <div>
            <div id="terms_heading">1. Information We Collect</div>
            <div id="terms_content">
              We collect the following types of personal information:
              <ul>
                <li>
                  Personal Identification Information: This includes your name,
                  email address, phone number, shipping address, billing
                  information, and payment details.
                </li>
                <li>
                  Technical Information: Information such as your IP address,
                  browser type, device identifiers, and how you navigate through
                  our website. This is typically collected through cookies and
                  similar tracking technologies.
                </li>
                <li>
                  Communication Data: Any data you provide when you communicate
                  with us via email, contact forms, or customer support,
                  including feedback, inquiries, and complaints.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">2. How We Use Your Information</div>
            <div id="terms_content">
              We use the information we collect for the following purposes:
              <ul>
                <li>
                  Order Processing: To fulfill and manage your orders, including
                  shipping, payment processing, and providing order
                  confirmations.
                </li>
                <li>
                  Customer Support: To provide you with assistance, answer your
                  questions, and handle any issues related to our products or
                  services.
                </li>
                <li>
                  Personalization: To customize your shopping experience by
                  showing you relevant products or suggestions based on your
                  preferences and previous purchases.
                </li>
                <li>
                  Marketing and Promotions: To send you promotional emails,
                  updates about our products, special offers, and other
                  marketing communications (with your consent where required by
                  law).
                </li>
                <li>
                  Website Improvement: To monitor website usage and optimize
                  your browsing experience by improving site functionality and
                  user experience.
                </li>
                <li>
                  Legal Compliance: To comply with any legal obligations, such
                  as tax reporting or responding to lawful requests from
                  authorities.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">3. How We Share Your Information</div>
            <div id="terms_content">
              We do not sell or rent your personal information to third parties.
              However, we may share your data with trusted partners under the
              following circumstances:
              <ul>
                <li>
                  Service Providers: We work with third-party companies to
                  provide services such as payment processing, order
                  fulfillment, shipping, email delivery, and website analytics.
                  These service providers are only allowed to access personal
                  information necessary to perform their services and are
                  contractually obligated to protect your data.
                </li>
                <li>
                  Legal Obligations: We may disclose your information if
                  required to do so by law or in response to valid requests from
                  government authorities, including to meet national security or
                  law enforcement requirements.
                </li>
                <li>
                  Business Transfers: In the event of a merger, acquisition, or
                  sale of assets, your personal information may be transferred
                  to the new owners as part of the transaction.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">4. Data Retention</div>
            <div id="terms_content">
              We will retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy or as
              required by law. Once your information is no longer needed, we
              will securely delete or anonymize it.
            </div>
          </div>
          <div>
            <div id="terms_heading">5. Your Rights and Choices</div>
            <div id="terms_content">
              You have certain rights regarding your personal information,
              including:
              <ul>
                <li>
                  Access and Correction: You can request access to the personal
                  data we hold about you and ask us to correct any inaccuracies.
                </li>
                <li>
                  Deletion: You can request that we delete your personal
                  information, subject to legal or contractual obligations.
                </li>
                <li>
                  Opt-Out of Marketing: You can opt out of receiving marketing
                  emails from us by following the unsubscribe link in the email
                  or contacting us directly.
                </li>
                <li>
                  Data Portability: You have the right to request that we
                  transfer your data to another service provider, where
                  technically feasible.
                </li>
                <li>
                  To exercise any of these rights, please contact us at
                  privacy@unprll.com.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div id="terms_heading">6. Security of Your Information</div>
            <div id="terms_content">
              We take the security of your personal data seriously and implement
              appropriate technical and organizational measures to protect it
              from unauthorized access, loss, or misuse. However, please note
              that no method of transmission over the internet or electronic
              storage is completely secure, and we cannot guarantee absolute
              security.
            </div>
          </div>
          <div>
            <div id="terms_heading">7. Children's Privacy</div>
            <div id="terms_content">
              Unprll does not knowingly collect or solicit personal information
              from individuals under the age of 16. If we learn that we have
              collected personal information from a child under 16 without
              parental consent, we will delete it promptly. If you believe we
              may have any information from or about a child under 16, please
              contact us at privacy@unprll.com.
            </div>
          </div>

          <div>
            <div id="terms_heading">8. Changes to This Privacy Policy</div>
            <div id="terms_content">
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page with an updated "Effective Date." We
              encourage you to review this policy periodically to stay informed
              about how we are protecting your personal data.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Privacy;
