import React from "react";
import fabric_img from "../../Assets/fabric_img.png";
import orders_img from "../../Assets/orders_img.png";
import sustain_img from "../../Assets/sustain_img.png";
import "../../Styles/WhyPrl.css";
function WhyPrl() {
  return (
    <div>
      <div className="container" id="whyprll">
        <div id="why_text">WHY</div>
        <div id="unprll_text">UNPRLL</div>
        <div id="why_sub_text">
          we deliver tailored solutions that meet the unique needs of your
          business
        </div>
        <div className="row" id="why_prl_img">
          <div className="col-lg-4 col-md-12">
            <div className="position-relative">
              <img src={fabric_img} alt="" width="100%" id="fabric_img" />
              <div
                id="top_qty_bg"
                className="position-absolute top-75 start-50 translate-middle"
              >
                <div id="top_qty_text">
                  Top Quality <br /> Fabrics
                </div>
                <div id="top_qty_subtext">
                  Our fabric guarantees a superior look and feel, enhancing the
                  overall experience.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="position-relative">
              <img src={orders_img} alt="" width="100%" id="orders_img" />
              <div
                id="top_qty_bg"
                className="position-absolute top-75 start-50 translate-middle"
              >
                <div id="top_qty_text">
                  Competitive Pricing <br />
                  for Bulk Orders
                </div>
                <div id="top_qty_subtext">
                Ensures that you get the best deal for high-quality products.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="position-relative">
              <img src={sustain_img} alt="" width="100%" id="sustain_img" />
              <div
                id="top_qty_bg"
                className="position-absolute top-75 start-50 translate-middle"
              >
                <div id="top_qty_text">
                  Sustainability <br />
                  Options
                </div>
                <div id="top_qty_subtext">
                Unprll is committed to sustainability by offering eco-friendly apparel solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhyPrl;
