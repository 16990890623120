import React, { useState } from "react";
import "../../Styles/Header.css";
import menu_icon from "../../Assets/header_menu_icon.png";
import un_logo from "../../Assets/un_logo.png";
import { Offcanvas } from "react-bootstrap";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div id="header_bg">
        <div id="header_text">UNPRLL FASHION</div>
      </div>
      <div id="header_inner_bg">
        <div className="container w-100 " id="header_inner_contents">
          <div id="header_left_menu" className="d-xl-flex d-lg-none d-md-none d-none">
            <a href="/#collection" className="text-decoration-none text-black">
              <div>COLLECTION</div>
            </a>
            <a href="/#whyprll" className="text-decoration-none text-black">
              <div>WHY UNPRLL</div>
            </a>
            <a href="/#industry" className="text-decoration-none text-black">
              <div>PARTNERS</div>
            </a>
          </div>
          <div
            className="d-lg-block d-xl-none d-md-block d-block"
            id="menu_icon"
            onClick={handleShow}
          >
            <img src={menu_icon} alt="" />
          </div>
          <a href="/">
            <div id="un_header_logo">
              <img src={un_logo} alt="" />
            </div>
          </a>

          <a href="/#contact" className="text-decoration-none">
            <button
              id="header_contact_button"
              className="btn d-xl-block d-lg-none d-md-block d-none"
            >
              CONTACT
            </button>
          </a>
          <div className="d-lg-none d-md-none d-block"></div>
        </div>
        <Offcanvas show={show} onHide={handleClose} placement="top" name="top">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <div id="header_mob_menu">
            <a href="/#collection" className="text-decoration-none text-black">
              <div onClick={handleClose}>COLLECTION</div>
            </a>
            <a href="/#whyprll" className="text-decoration-none text-black">
              <div onClick={handleClose}>WHY UNPRLL</div>
            </a>
            <a href="/#industry" className="text-decoration-none text-black">
              <div onClick={handleClose}>PARTNERS</div>
            </a>
            <a href="/#contact" id="contact_btn">
              <button id="header_contact_button" className="w-100 btn" onClick={handleClose}>
                CONTACT
              </button>
            </a>
          </div>
        </Offcanvas>
      </div>
    </>
  );
}

export default Header;
