import React from "react";
import "../../Styles/OurClient.css";
import client_heart from "../../Assets/client_heart.png";
import client_img from "../../Assets/client_img.png";
import client_img2 from "../../Assets/client_img2.png";
import client_img3 from "../../Assets/client_img3.png";
import client_img4 from "../../Assets/client_img4.png";
function OurClient() {
  return (
    <div className="container">
      <div id="our_client_bg">
        <div id="our_client_text">
          <img src={client_heart} alt="" id="heart_img" />
          <div>Our Clients Words</div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div id="client_review_bg">
              <div id="client_review_text">
                Unprll made bulk ordering t-shirts effortless! From design to
                delivery, everything was smooth and on time. Highly
                recommended!!
              </div>
              <div className="d-flex" id="client_bg">
                <div>
                  <img src={client_img} alt="" />
                </div>
                <div id="client_name">
                Amy<div id="client_loc">Manchester</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div id="client_review_bg">
              <div id="client_review_text">
                We needed custom shirts for our event, and Unprll nailed it. The
                designs came out perfectly, and the process was seamless!
              </div>
              <div className="d-flex" id="client_bg_img">
                <div>
                  <img src={client_img2} alt="" />
                </div>
                <div id="client_name">
                  Josh<div id="client_loc">New Jersey</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div id="client_review_bg">
              <div id="client_review_text">
                Unprll is our go-to for bulk t-shirt orders. The customer
                service was fantastic, and the t-shirts were high quality at a
                great price!
              </div>
              <div className="d-flex" id="client_bg_img">
                <div>
                  <img src={client_img3} alt="" />
                </div>
                <div id="client_name">
                  Sarah M<div id="client_loc">Ottawa</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div id="client_review_bg">
              <div id="client_review_text">
                Great experience! The customization process was seamless, and
                our company shirts turned out perfect.
              </div>
              <div className="d-flex" id="client_bg_img">
                <div>
                  <img src={client_img4} alt="" />
                </div>
                <div id="client_name">
                  David S<div id="client_loc">Ohio</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClient;
