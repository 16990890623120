import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Collection from "./Collection";
import Suppliers from "./Suppliers";
import WhyPrl from "./WhyPrl";
import OurClient from "./OurClients";
import PartnerIndustry from "./PartnerIndustry";
import Contact from "./Contact";
import Footer from "./Footer";

function HomePage() {
  return (
    <>
      <Header />
      <HeroSection />
      <Collection />
      <Suppliers />
      <WhyPrl />
      <OurClient />
      <PartnerIndustry />
      <Contact />
      <Footer />
    </>
  );
}

export default HomePage;
