import React from "react";
import Header from "../Homepage/Header";
import "../../Styles/Policies.css";
import privacy_img from "../../Assets/terms_img.png";
import Footer from "../Homepage/Footer";
function TermsConditions() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row p-0 m-0" id="privacy_bg">
          <div className="col-md-6">
            <div id="terms_text">Terms & Condition</div>
          </div>
          <div className="col-md-6 d-lg-block d-md-none d-none" id="privacy_img">
            <img src={privacy_img} alt="" />
          </div>
        </div>
        <div id="terms_conditions_bg">
          <div>
            <div id="terms_heading">1. Introduction</div>
            <div id="terms_content">
              Welcome to Unprll! By accessing and using our website
              (www.unprll.com), you agree to comply with and be bound by the
              following terms and conditions. Please review them carefully. If
              you do not agree with these terms, you should not use our services
              or website.
            </div>
          </div>
          <div>
            <div id="terms_heading">2. Use of Website</div>
            <div id="terms_content">
              You agree to use the website for lawful purposes only.
              Unauthorized use of this website may give rise to a claim for
              damages and/or be a criminal offense. We reserve the right to
              restrict or terminate your access to the website at any time
              without notice.
            </div>
          </div>
          <div>
            <div id="terms_heading">3. Products and Orders</div>
            <div id="terms_content">
              Bulk Orders: Unprll specializes in bulk t-shirt and apparel
              orders. Minimum quantities may apply. Product Availability: While
              we strive to ensure that all products listed on our site are in
              stock, availability is not guaranteed. We reserve the right to
              modify or discontinue products without notice.
            </div>
          </div>
          <div>
            <div id="terms_heading">4. Shipping and Delivery</div>
            <div id="terms_content">
              Delivery Time: Shipping times may vary depending on your location
              and the size of your order. Estimated delivery times will be
              provided during the checkout process. International Shipping:
              Unprll offers worldwide shipping. International orders may be
              subject to customs duties and taxes, which are the responsibility
              of the buyer. Delays: Unprll is not responsible for delays caused
              by third-party carriers or customs.
            </div>
          </div>
          <div>
            <div id="terms_heading">5. Returns and Refunds</div>
            <div id="terms_content">
              Custom Products: Due to the nature of custom-designed t-shirts and
              apparel, returns are generally not accepted unless the product is
              defective or incorrect. Refunds: In the event of a return, refunds
              will be issued to the original payment method after the returned
              product is inspected. Refunds for defective items will be
              processed within 7-10 business days.
            </div>
          </div>
          <div>
            <div id="terms_heading">6. Intellectual Property</div>
            <div id="terms_content">
              All content on the Unprll website, including but not limited to
              images, logos, text, graphics, and product designs, is owned by or
              licensed to Unprll. You may not reproduce, distribute, or use any
              content without prior written permission from Unprll.
            </div>
          </div>
          <div>
            <div id="terms_heading">7. Privacy Policy</div>
            <div id="terms_content">
              All content on the Unprll website, including but not limited to
              images, logos, text, graphics, and product designs, is owned by or
              licensed to Unprll. You may not reproduce, distribute, or use any
              content without prior written permission from Unprll.
            </div>
          </div>

          <div>
            <div id="terms_heading">8. Limitation of Liability</div>
            <div id="terms_content">
              Unprll is not liable for any indirect, incidental, or
              consequential damages that may arise from the use of this website
              or its products. In the event of a direct loss caused by Unprll's
              negligence, our liability is limited to the total purchase amount
              of the order in question.
            </div>
          </div>
          <div>
            <div id="terms_heading">9. Governing Law</div>
            <div id="terms_content">
              These Terms and Conditions are governed by and construed in
              accordance with the laws of [Your Jurisdiction]. Any disputes
              arising out of the use of this website shall be resolved in the
              courts of [Your Jurisdiction].
            </div>
          </div>
          <div>
            <div id="terms_heading">10. Changes to Terms and Conditions</div>
            <div id="terms_content">
              Unprll reserves the right to update or modify these Terms and
              Conditions at any time without prior notice. Your continued use of
              the website after changes are made signifies your acceptance of
              the new Terms and Conditions.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default TermsConditions;
